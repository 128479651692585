.branding-title {
	margin: 0;
	font-weight: normal;
	line-height: 0;
}

.logo {
	display: block;
	width: 100%;
}

	.branding-logo {
		max-width: 240px;
	}

	.site-info-logo {}