.front_think {
	padding: 0;
	background-color: $body-bg;
	.think-wrapper {
		padding: 20px 0;
		background-color: $color-primary;
	  	/* background: linear-gradient(
	  		to right,
	  		theme(primary, dark),
	  		theme(primary, base) 25%,
	  		theme(primary, base) 75%,
	  		theme(primary, dark)
	  			); */
		@include gdfancy-bg($color-primary);
	}

	@include media-breakpoint-up(lg) {
		.row { align-items: center; }
		.block_media { @include make-col(8); }
		.block_text { @include make-col(8); padding-right: 0; }
	}
}

	.think_text {
		@include font-size(19);
		font-weight: $body-weight-bold;
		line-height: 1.125em;
		letter-spacing: -0.0375em;
		text-align: center;
		text-shadow: 0 1px 2px rgba(black,0.25);
		color: $white;
		strong {
			display: block;
			font-size: 125%;
			font-weight: $body-weight-bolder;
		}
		@include media-breakpoint-up(lg) {
			text-align: left;
		}
		@include media-breakpoint-up(xl) {
			@include font-size(21);
		}
	}