.block_announce {
	padding: 15px;
	font-weight: $body-weight-bolder;
	letter-spacing: -0.0425em;
	line-height: 1em;
	text-align: center;
	text-shadow: 0 1px 1px rgba(black,0.3);
	border-bottom: 3px solid $white;
	background-color: $color-primary;
  	background: linear-gradient(
  		to right,
  		theme(primary, dark),
  		theme(primary, base) 25%,
  		theme(primary, base) 75%,
  		theme(primary, dark)
	);

	color: $white;

	.page_front & { border: none; }

	@include media-breakpoint-up(md) {
		.list-announce {
			@include make-flex;
			flex-wrap: nowrap;
			align-items: center;
			li {
				margin: 0;
				width: percentage(1/3);
			}
		}
	}

}