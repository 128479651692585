body {

	min-width: 360px;
	background-color: $body-bg;
	background: 
		repeating-linear-gradient(
		to right,
		transparent,
		transparent 89px,
		#eee 89px,
		#eee 90px
		),
		repeating-linear-gradient(
		to bottom,
		transparent,
		transparent 89px,
		#eee 89px,
		#eee 90px
		),
		repeating-linear-gradient(
		-45deg,
		#f9f9f9,
		#f9f9f9 200px,
		transparent 200px,
		transparent 400px
		);

	&:not(.page_front) {
			
		@include media-breakpoint-up(sm) {
			padding-bottom: 121px; height: 100%; 
			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}
		}
		
		@include media-breakpoint-up(lg) {
			padding-bottom: 124px;
		}

	}

}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}