@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0;
  background-color: #fafafa;
  color: #444; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container [class*="col-"] {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding: 0 15px; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }
  @media (min-width: 576px) {
    .flex_container .col-sm-12 {
      width: 100%; }
    .flex_container .col-sm-11 {
      width: 91.66667%; }
    .flex_container .col-sm-10 {
      width: 83.33333%; }
    .flex_container .col-sm-9 {
      width: 75%; }
    .flex_container .col-sm-8 {
      width: 66.66667%; }
    .flex_container .col-sm-7 {
      width: 58.33333%; }
    .flex_container .col-sm-6 {
      width: 50%; }
    .flex_container .col-sm-5 {
      width: 41.66667%; }
    .flex_container .col-sm-4 {
      width: 33.33333%; }
    .flex_container .col-sm-3 {
      width: 25%; }
    .flex_container .col-sm-2 {
      width: 16.66667%; }
    .flex_container .col-sm-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) {
    .flex_container .col-md-12 {
      width: 100%; }
    .flex_container .col-md-11 {
      width: 91.66667%; }
    .flex_container .col-md-10 {
      width: 83.33333%; }
    .flex_container .col-md-9 {
      width: 75%; }
    .flex_container .col-md-8 {
      width: 66.66667%; }
    .flex_container .col-md-7 {
      width: 58.33333%; }
    .flex_container .col-md-6 {
      width: 50%; }
    .flex_container .col-md-5 {
      width: 41.66667%; }
    .flex_container .col-md-4 {
      width: 33.33333%; }
    .flex_container .col-md-3 {
      width: 25%; }
    .flex_container .col-md-2 {
      width: 16.66667%; }
    .flex_container .col-md-1 {
      width: 8.33333%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .flex_container .col-md-break {
      width: 100%; } }
  @media (min-width: 992px) {
    .flex_container .col-lg-12 {
      width: 100%; }
    .flex_container .col-lg-11 {
      width: 91.66667%; }
    .flex_container .col-lg-10 {
      width: 83.33333%; }
    .flex_container .col-lg-9 {
      width: 75%; }
    .flex_container .col-lg-8 {
      width: 66.66667%; }
    .flex_container .col-lg-7 {
      width: 58.33333%; }
    .flex_container .col-lg-6 {
      width: 50%; }
    .flex_container .col-lg-5 {
      width: 41.66667%; }
    .flex_container .col-lg-4 {
      width: 33.33333%; }
    .flex_container .col-lg-3 {
      width: 25%; }
    .flex_container .col-lg-2 {
      width: 16.66667%; }
    .flex_container .col-lg-1 {
      width: 8.33333%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .flex_container .col-lg-break {
      width: 100%; } }
  @media (min-width: 1200px) {
    .flex_container .col-xl-break {
      width: 100%; }
    .flex_container .col-xl-12 {
      width: 100%; }
    .flex_container .col-xl-11 {
      width: 91.66667%; }
    .flex_container .col-xl-10 {
      width: 83.33333%; }
    .flex_container .col-xl-9 {
      width: 75%; }
    .flex_container .col-xl-8 {
      width: 66.66667%; }
    .flex_container .col-xl-7 {
      width: 58.33333%; }
    .flex_container .col-xl-6 {
      width: 50%; }
    .flex_container .col-xl-5 {
      width: 41.66667%; }
    .flex_container .col-xl-4 {
      width: 33.33333%; }
    .flex_container .col-xl-3 {
      width: 25%; }
    .flex_container .col-xl-2 {
      width: 16.66667%; }
    .flex_container .col-xl-1 {
      width: 8.33333%; } }

@media (min-width: 992px) {
  .content .main {
    flex: 0 0 62.5%;
    max-width: 62.5%;
    padding-right: 20px; }
  .content .sidebar {
    flex: 0 0 37.5%;
    max-width: 37.5%;
    padding-left: 20px; } }

@media (min-width: 992px) {
  .content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .page_contact .sidebar {
    flex: 0 0 43.75%;
    max-width: 43.75%; }
  .page_contact .main {
    flex: 0 0 56.25%;
    max-width: 56.25%; } }

@media (min-width: 992px) {
  .content.flip .main {
    order: 2; }
  .content.flip .sidebar {
    order: 1; } }

body {
  min-width: 360px;
  background-color: #fafafa;
  background: repeating-linear-gradient(to right, transparent, transparent 89px, #eee 89px, #eee 90px), repeating-linear-gradient(to bottom, transparent, transparent 89px, #eee 89px, #eee 90px), repeating-linear-gradient(-45deg, #f9f9f9, #f9f9f9 200px, transparent 200px, transparent 400px); }
  @media (min-width: 576px) {
    body:not(.page_front) {
      padding-bottom: 121px;
      height: 100%; }
      body:not(.page_front) .site_info {
        position: absolute;
        bottom: 0;
        width: 100%; } }
  @media (min-width: 992px) {
    body:not(.page_front) {
      padding-bottom: 124px; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  letter-spacing: -0.0275em;
  line-height: 1.64706em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 992px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h5, .h5,
h6, .h6, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin: 30px 0 15px;
  font-weight: 400;
  line-height: 1em;
  letter-spacing: -0.0425em; }
  h5 small, .h5 small,
  h6 small, .h6 small, h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, h1 small, .h1 small {
    display: block;
    font-size: 75%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: -0.0125em;
    line-height: 1em;
    color: #888; }

h5, .h5,
h6, .h6 {
  font-size: 16px;
  font-size: 1rem; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 17px;
      font-size: 1.0625rem; } }

h4, .h4 {
  font-size: 18px;
  font-size: 1.125rem; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 20px;
      font-size: 1.25rem; } }

h3, .h3 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h3 small, .h3 small {
    font-weight: 600; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 23px;
      font-size: 1.4375rem; } }

h2, .h2 {
  font-size: 22px;
  font-size: 1.375rem; }
  @media (min-width: 992px) {
    h2, .h2 {
      font-size: 27px;
      font-size: 1.6875rem; } }

h1, .h1 {
  font-size: 27px;
  font-size: 1.6875rem;
  font-weight: 800;
  letter-spacing: -0.0525em; }
  h1 small, .h1 small {
    font-weight: 600; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 36px;
      font-size: 2.25rem; } }

p {
  margin: 0 0 15px; }
  p.lead {
    font-size: 118%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin: 0 0 15px;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 110%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #444; }
  blockquote.bq--primary {
    border-color: #dc0230; }
  blockquote.bq--secondary {
    border-color: #17378e; }
  blockquote.bq--highlight {
    border-color: #41bcf8; }
  blockquote.bq--accent {
    border-color: #0b3954; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #d61b0a; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  margin-top: 0;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #444; }
  .bq--primary .bq--title {
    color: #dc0230; }
  .bq--secondary .bq--title {
    color: #17378e; }
  .bq--highlight .bq--title {
    color: #41bcf8; }
  .bq--accent .bq--title {
    color: #0b3954; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #d61b0a; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #ddd;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #17378e; }
  a:hover, a:focus {
    color: #1e48ba;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #446ee1; }
  a.mask {
    text-decoration: none;
    color: inherit; }
    a.mask:hover {
      color: inherit; }

a.img-link {
  text-decoration: none; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }
  .list li > ul {
    margin-top: 0.25em !important; }

.list-inline, .menu, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

@media (min-width: 768px) {
  .list-wrap {
    display: flex;
    flex-wrap: wrap; }
    .list-wrap.wrap-2 .list {
      padding: 0 25px; } }

.--has-icon {
  vertical-align: middle; }
  .--has-icon > * {
    vertical-align: middle; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: 0;
  vertical-align: middle; }

.icon-12 {
  width: 12px;
  height: 12px; }

.icon-16 {
  width: 16px;
  height: 16px; }

.icon-18 {
  width: 18px;
  height: 18px; }

.icon-64 {
  width: 64px;
  height: 64px; }

.icon-primary {
  fill: #dc0230; }

.icon-secondary {
  fill: #17378e; }

.icon-highlight {
  fill: #41bcf8; }

.icon-accent {
  fill: #0b3954; }

.icon-green {
  fill: #42aa14; }

.icon-blue {
  fill: #0c84ea; }

.icon-orange {
  fill: #ff8400; }

.icon-purple {
  fill: #cc00ff; }

.icon-white {
  fill: #fff; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-responsive {
  width: 100%; }

.img-main {
  margin: 0 0 15px;
  max-width: 360px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 45%;
    margin: 0 0 15px 20px;
    max-width: none; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 45%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  padding: 8px;
  border: 1px solid #ccc;
  background-color: #fff;
  margin-bottom: 15px; }
  .img-captioned img {
    padding: 0;
    border: none;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 10px 5px 2px;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em; }

div.img-captioned {
  max-width: 360px; }
  div.img-captioned img {
    width: 100%; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 0.5em 0.75em;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.best-rating {
  display: inline-block;
  padding: 5px;
  line-height: 0;
  text-decoration: none;
  color: #444 !important; }
  .best-rating svg {
    display: inline;
    width: auto;
    height: 20px; }
    .best-rating svg.icon-google {
      width: 20px; }
    .best-rating svg.icon-stars {
      width: 100px;
      fill: #f90; }

.best-title {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1em;
  font-weight: 400; }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-weight: 600;
  line-height: 1em;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid;
  -webkit-transition: all 200ms;
  -moz-transition: all 200ms;
  -ms-transition: all 200ms;
  -o-transition: all 200ms;
  transition: all 200ms; }
  .btn:hover, .btn:focus {
    -webkit-transition: all 200ms;
    -moz-transition: all 200ms;
    -ms-transition: all 200ms;
    -o-transition: all 200ms;
    transition: all 200ms;
    cursor: pointer; }
  .btn.btn--sm {
    font-size: 85%;
    padding: 8px 12px; }
  .btn.btn--lg {
    font-size: 115%;
    padding: 16px 20px; }
  .btn.btn--wide {
    display: block;
    width: 100%; }
  .btn small {
    display: block; }

.btn:not(.btn-social) {
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  box-shadow: 0 10px 5px -10px rgba(0, 0, 0, 0.6); }
  .btn:not(.btn-social):hover, .btn:not(.btn-social):focus {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    box-shadow: none; }

.--solid-primary {
  border: 1px solid #c3022a;
  background-color: #dc0230;
  color: #fff !important; }
  .--solid-primary:hover, .--solid-primary:focus {
    background-color: #f50236;
    color: #fff !important; }

.--solid-secondary {
  border: 1px solid #132f78;
  background-color: #17378e;
  color: #fff !important; }
  .--solid-secondary:hover, .--solid-secondary:focus {
    background-color: #1b40a4;
    color: #fff !important; }

.--solid-highlight {
  border: 1px solid #28b3f7;
  background-color: #41bcf8;
  color: #fff !important; }
  .--solid-highlight:hover, .--solid-highlight:focus {
    background-color: #5ac5f9;
    color: #fff !important; }

.--solid-accent {
  border: 1px solid #082a3d;
  background-color: #0b3954;
  color: #fff !important; }
  .--solid-accent:hover, .--solid-accent:focus {
    background-color: #0e486b;
    color: #fff !important; }

.--solid-review {
  border: 1px solid #e68a00;
  background-color: #f90;
  color: #fff !important; }
  .--solid-review:hover, .--solid-review:focus {
    background-color: #ffa31a;
    color: #fff !important; }

.--solid-success {
  border: 1px solid #23923d;
  background-color: #28a745;
  color: #fff !important; }
  .--solid-success:hover, .--solid-success:focus {
    background-color: #2dbc4e;
    color: #fff !important; }

.--solid-danger {
  border: 1px solid #be1809;
  background-color: #d61b0a;
  color: #fff !important; }
  .--solid-danger:hover, .--solid-danger:focus {
    background-color: #ee1e0b;
    color: #fff !important; }

.--solid-warning {
  border: 1px solid #edb100;
  background-color: #ffc107;
  color: #fff !important; }
  .--solid-warning:hover, .--solid-warning:focus {
    background-color: #ffc721;
    color: #fff !important; }

.--solid-info {
  border: 1px solid #0172af;
  background-color: #0182C8;
  color: #fff !important; }
  .--solid-info:hover, .--solid-info:focus {
    background-color: #0192e1;
    color: #fff !important; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #a51508;
  background-color: #d61b0a;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #d61b0a;
    background-color: #f5301e; }

.btn-mobile {
  width: 48px;
  height: 41px;
  padding: 10px 11px 9px;
  background-color: #17378e;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .btn-mobile:hover, .btn-mobile:focus {
    outline: none;
    background-color: #1e48ba;
    cursor: pointer; }
  .btn-mobile b {
    display: none; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 26px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 5px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 9px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -16px; }

.gmb-button-review {
  display: flex;
  box-sizing: border-box;
  flex-wrap: 1;
  width: 240px;
  height: 61px;
  padding: 0 8px;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em;
  text-decoration: none;
  border-radius: 6px;
  border: 0.075em solid #fff;
  background-image: linear-gradient(to right, #fff 50%, #4285f4 50%);
  color: #fff !important; }
  .gmb-button-review svg {
    align-self: center; }
  .gmb-button-review div {
    display: flex;
    align-items: center;
    height: 59px;
    margin-left: 8px;
    padding: 10px;
    text-align: left;
    background-color: #4285F4; }

.review-icon.icon-g {
  width: 40px;
  height: 41px; }

.review-icon.icon-bbb {
  width: 240px;
  height: 61px; }

.btn-social.--facebook {
  border: 1px solid #3b5c9f;
  background-color: #4267b2;
  color: #fff !important; }
  .btn-social.--facebook:hover, .btn-social.--facebook:focus {
    background-color: #5074be;
    color: #fff !important; }

.phone {
  text-decoration: none;
  color: #dc0230; }
  .phone:hover {
    cursor: default;
    color: #dc0230; }

.contact-call {
  line-height: 1em; }

.banner-call {
  margin-bottom: 0; }

.contact-title {
  display: block;
  margin-bottom: 2px;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0425em; }

.contact-subtitle {
  display: block;
  margin-top: 3px;
  font-size: 100%; }

.banner-phone {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -0.0525em; }

.coverage {
  line-height: 1.275em; }
  .coverage > *:last-child {
    margin-bottom: 0; }
  .coverage p {
    margin-bottom: 8px; }

.coverage_title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800; }

.coverage_county {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 600; }

.coverage_city {
  font-size: 13px;
  font-size: 0.8125rem; }

.form {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px 15px 7px;
  border: 1px solid #ccc; }
  .form_section hr {
    margin: 15px 0 20px;
    background-color: #c5c5c5; }
  @media (min-width: 768px) {
    .form_section .form--left,
    .form_section .form_city {
      float: left;
      clear: both;
      width: 49%; }
    .form_section .form--right {
      float: right;
      width: 49%; }
    .form_section .form_state {
      float: left;
      width: 23.5%;
      margin: 0 2%; }
    .form_section .form_zip {
      float: right;
      width: 23.5%; }
    .form_section .form--clear {
      clear: both; }
    .form_section .checkbox_group {
      display: flex;
      flex-wrap: wrap; }
      .form_section .checkbox_group .form_checkbox {
        width: 50%; }
    .form_section .form_radio {
      display: flex;
      flex-wrap: wrap; }
      .form_section .form_radio label {
        margin-right: 15px; } }

.section-title {
  padding: 5px 45px 5px 10px;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: -0.0475em; }

.section-subtitle {
  margin: 10px 0;
  font-size: 16px;
  font-size: 1rem;
  font-weight: 600;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  letter-spacing: -0.0475em;
  color: #444; }

.form-title {
  margin: 0 0 10px;
  font-weight: 600; }
  .form-title small {
    font-size: 15px;
    font-size: 0.9375rem; }

.form_group {
  position: relative;
  margin: 0 0 5px; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 10px 0 3px;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1em; }

.form_control {
  display: block;
  width: 100%;
  padding: 5px;
  line-height: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 100px;
  line-height: 1.275em; }

.form_radio,
.form_checkbox {
  vertical-align: top;
  line-height: 1em; }
  .form_radio label,
  .form_checkbox label {
    display: block;
    clear: both;
    font-size: 15px;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_radio .checkbox, .form_radio .radio,
  .form_checkbox .checkbox,
  .form_checkbox .radio {
    display: block;
    margin: 0 15px 0 0;
    float: left;
    width: 20px;
    height: 20px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #d61b0a !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #d61b0a;
  color: #fff; }

.form_spam {
  position: relative;
  height: 61px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -8px;
    left: -31px;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8); }

.form_quick {
  padding: 20px;
  background-color: #fff; }
  @media (min-width: 768px) and (max-width: 991px) {
    .form_quick {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .form_quick .form-title {
        width: 100%; }
      .form_quick .form_wrap_info {
        width: 49%; }
      .form_quick .form_wrap_comments {
        width: 49%; }
      .form_quick .form_wrap_submit {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        flex-wrap: nowrap;
        margin-top: 10px; }
        .form_quick .form_wrap_submit .form_submit {
          margin-left: 270px; } }
  .page_contact .form_quick {
    padding: 0;
    background-color: transparent; }

.form_quote .form_group.form_radio label {
  margin-bottom: 10px;
  padding: 5px; }
  .form_quote .form_group.form_radio label .radio {
    margin-top: -3px; }

.form_quote .checkbox_group .form_group label {
  padding: 5px; }

.form_quote .checkbox_group .form_group .checkbox {
  margin-top: -2px; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }
  .gallery img:not(:last-child) {
    margin-bottom: 15px; }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

.hours {
  margin: 0 0 10px;
  line-height: 1em; }

.hours-title {
  font-size: inherit; }

.license {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1em; }

.license-title {
  font-size: inherit; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 28px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: #fff;
  background-color: transparent;
  color: #444; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.branding-title {
  margin: 0;
  font-weight: normal;
  line-height: 0; }

.logo {
  display: block;
  width: 100%; }

.branding-logo {
  max-width: 240px; }

.g_map {
  position: relative;
  background-image: url("/_/images/layout/gmap.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .g_map:hover {
    cursor: pointer; }
    .g_map:hover p {
      opacity: 1; }
  .g_map p {
    position: absolute;
    top: 45%;
    width: 100%;
    padding: 0.5em;
    font-size: 20px;
    font-size: 1.25rem;
    /* font-family: $font-family-headings;
		font-weight: $font-weight-bold; */
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(23, 55, 142, 0.8);
    color: #fff;
    opacity: 0; }

.menu {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.menu-item {
  box-sizing: border-box;
  list-style-type: none; }
  .menu-item.active .menu-link {
    cursor: default; }
    .menu-item.active .menu-link:hover {
      cursor: default; }
  .menu-item.open .dropdown-menu {
    display: block;
    height: auto; }
  .menu-item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.menu-link {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .menu-link:hover, .menu-link:focus {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    outline: none; }

.caret,
.caret-push {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  top: 2px;
  vertical-align: top;
  fill: #fff; }

.caret-push {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  margin-right: 0;
  width: 0; }

.dropdown-menu {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  min-width: 280px;
  background-color: #fff; }
  .dropdown-menu .menu-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown-menu .menu-link:hover .caret-push, .dropdown-menu .menu-link:focus .caret-push {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    width: 12px;
    margin-right: 3px; }
  @media (min-width: 768px) {
    .dropdown-menu {
      position: absolute; } }

.banner_menu .menu {
  display: flex !important;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center; }
  .banner_menu .menu > .menu-item {
    display: inline-block;
    flex: 1;
    padding: 0 5px; }
    .banner_menu .menu > .menu-item.active > .menu-link {
      background: transparent !important;
      color: #dc0230 !important; }
      .banner_menu .menu > .menu-item.active > .menu-link b {
        font-weight: 800 !important; }
    .banner_menu .menu > .menu-item.open > .menu-link {
      background-color: #41bcf8; }
      .banner_menu .menu > .menu-item.open > .menu-link .caret {
        fill: #fff !important; }
    .banner_menu .menu > .menu-item > .menu-link {
      padding: 12px 18px;
      font-weight: 600;
      border-radius: 4px;
      background: #17378e url("/_/images/layout/hover.svg") center center no-repeat;
      background-size: 0;
      color: #fff; }
      .banner_menu .menu > .menu-item > .menu-link .caret {
        fill: #fff; }
      .banner_menu .menu > .menu-item > .menu-link:hover {
        background-size: 115%; }

.banner_menu .menu-link {
  display: block;
  padding: 10px;
  font-weight: 600;
  white-space: nowrap;
  background-color: transparent;
  color: #fff; }

.banner_menu .dropdown-menu {
  margin-top: 5px;
  border-radius: 4px;
  background-color: #41bcf8;
  /* 		@include media-breakpoint-up(lg) {
	background-color: theme(primary, base);
	.menu-item.active .menu-link { color: theme(secondary, base); }
	.menu-link {
		&:hover { color: theme(secondary, base); }
		.caret-push { fill: theme(secondary, base); }
	}
} */ }
  .banner_menu .dropdown-menu .menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.16); }
  .banner_menu .dropdown-menu .menu-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.48); }
  .banner_menu .dropdown-menu .menu-item.active .menu-link {
    color: #dc0230;
    letter-spacing: -0.0275em;
    font-weight: 800; }
    .banner_menu .dropdown-menu .menu-item.active .menu-link .caret-push {
      width: 0;
      margin-right: 0; }
  .banner_menu .dropdown-menu .menu-link .caret-push {
    fill: #fff; }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 90%;
  max-width: 320px;
  height: 100%;
  padding: 30px 15px;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #17378e;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile h4 {
    font-size: 1.2em;
    font-weight: 800;
    text-transform: uppercase; }
  .nav_mobile .menu {
    border-bottom: 1px solid #fff;
    padding-bottom: 30px;
    margin-bottom: 10px; }
  .nav_mobile .menu > .menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .nav_mobile .menu > .menu-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.2); }
  .nav_mobile .menu > .menu-item.active .menu-link {
    font-weight: 800;
    background-color: #fff;
    color: #dc0230; }
    .nav_mobile .menu > .menu-item.active .menu-link:hover, .nav_mobile .menu > .menu-item.active .menu-link:focus {
      outline: none;
      background-color: #fff;
      color: #dc0230; }
  .nav_mobile .menu > .menu-item.open {
    border-bottom: none; }
    .nav_mobile .menu > .menu-item.open > .menu-link {
      background-color: #dc0230; }
  .nav_mobile .menu > .menu-item > .menu-link {
    padding: 12px 10px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .menu > .menu-item > .menu-link:hover, .nav_mobile .menu > .menu-item > .menu-link:focus {
      outline: none;
      background-color: #dc0230;
      color: #fff; }
  .nav_mobile .dropdown-menu {
    min-width: 0;
    margin-top: 5px;
    padding: 0;
    background-color: #dc0230; }
    .nav_mobile .dropdown-menu .menu-item {
      font-size: 0.925em; }
      .nav_mobile .dropdown-menu .menu-item:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
      .nav_mobile .dropdown-menu .menu-item:not(:first-child) {
        border-top: 1px solid rgba(255, 255, 255, 0.3); }
      .nav_mobile .dropdown-menu .menu-item.active .menu-link {
        color: #dc0230;
        letter-spacing: -0.0275em;
        font-weight: 800; }
        .nav_mobile .dropdown-menu .menu-item.active .menu-link .caret-push {
          width: 0;
          margin-right: 0; }
    .nav_mobile .dropdown-menu .menu-link {
      font-weight: 600;
      background-color: transparent;
      color: #fff; }
      .nav_mobile .dropdown-menu .menu-link:hover, .nav_mobile .dropdown-menu .menu-link:focus {
        outline: none;
        letter-spacing: -0.0275em;
        color: #dc0230; }
      .nav_mobile .dropdown-menu .menu-link .caret-push {
        fill: #dc0230; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.review .review-wrap {
  padding: 0 15px; }
  .review .review-wrap .icon-review {
    margin: 0 auto 10px; }

.review p {
  margin-bottom: 0;
  line-height: 1.425em; }

.review cite {
  display: block;
  margin-top: 5px; }

.review .icon-review {
  display: block;
  height: 25px;
  width: 125px;
  margin: 0 0 15px;
  fill: #f90; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  width: 44px;
  height: 44px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.4);
  background-size: 30%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  opacity: 0.5; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none; }

.slick-prev {
  left: 10px;
  background-image: url("/_/images/icons/angle_left.png"); }

.slick-next {
  right: 10px;
  background-image: url("/_/images/icons/angle_right.png"); }

.slider_jumbo {
  margin: 0 auto;
  line-height: 0px; }
  .slider_jumbo .slick-prev,
  .slider_jumbo .slick-next {
    opacity: 0; }
  .slider_jumbo .slide-placeholder {
    width: 100%; }
  .slider_jumbo .slide {
    position: relative; }
  .slider_jumbo .slide_caption {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    background-color: rgba(65, 188, 248, 0.8);
    color: #fff; }
    .slider_jumbo .slide_caption strong {
      font-weight: 800; }
  @media (min-width: 768px) {
    .slider_jumbo .slide_caption {
      font-size: 24px;
      font-size: 1.5rem; } }
  @media (min-width: 992px) {
    .slider_jumbo .slide {
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d; }
    .slider_jumbo .slide_caption {
      position: absolute;
      top: 50%;
      right: auto;
      bottom: auto;
      display: inline-block;
      width: auto;
      padding: 15px 90px 15px 15px;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 30px;
      font-size: 1.875rem;
      letter-spacing: -0.0425em;
      text-align: left;
      background-color: transparent;
      background-image: linear-gradient(to right, rgba(65, 188, 248, 0.8) 70%, transparent); }
      .slider_jumbo .slide_caption strong {
        display: block; } }
  @media (min-width: 1200px) {
    .slider_jumbo .slide_caption {
      font-size: 36px;
      font-size: 2.25rem;
      letter-spacing: -0.0575em; } }

.slider_reviews {
  padding: 0 36px; }
  .slider_reviews .slick-prev,
  .slider_reviews .slick-next {
    z-index: 999999;
    height: 100%;
    width: 23px;
    background-color: transparent;
    background-image: url("/_/images/icons/slide-np-text.png") !important;
    background-size: 40px 20px;
    opacity: 0.3; }
    .slider_reviews .slick-prev:hover,
    .slider_reviews .slick-next:hover {
      opacity: 0.7; }
  .slider_reviews .slick-prev {
    left: 2px;
    background-position: 0 center; }
  .slider_reviews .slick-next {
    right: 0;
    background-position: -20px center; }

.youtube-player {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000; }
  .youtube-player.yt-16x9 {
    padding-bottom: 56.23%; }
  .youtube-player.yt-4x3 {
    padding-bottom: 75%; }

.youtube-player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: transparent; }

.youtube-player img {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: auto;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms; }
  .youtube-player img:hover {
    opacity: 1.0; }

.youtube-player .play {
  position: absolute;
  z-index: 5000;
  height: 72px;
  width: 72px;
  left: 50%;
  top: 50%;
  margin-left: -36px;
  margin-top: -36px;
  background: url("/_/images/layout/play.png") no-repeat;
  cursor: pointer; }
  .youtube-player .play:hover + img {
    opacity: 1.0; }

.widget {
  margin-bottom: 30px;
  padding: 15px;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.275em;
  border-radius: 6px;
  border: 2px solid #ccc;
  background-color: #fff; }
  .widget.widget-primary {
    border-color: #dc0230; }
    .widget.widget-primary .widget-title {
      color: #dc0230; }
    .widget.widget-primary a:not(.menu-link):not(.btn) {
      color: #dc0230 !important; }
  .widget.widget-secondary {
    border-color: #17378e; }
    .widget.widget-secondary .widget-title {
      color: #17378e; }
    .widget.widget-secondary a:not(.menu-link):not(.btn) {
      color: #17378e !important; }
  .widget.widget-highlight {
    border-color: #41bcf8; }
    .widget.widget-highlight .widget-title {
      color: #41bcf8; }
    .widget.widget-highlight a:not(.menu-link):not(.btn) {
      color: #41bcf8 !important; }
  .widget.widget-accent {
    border-color: #0b3954; }
    .widget.widget-accent .widget-title {
      color: #0b3954; }
    .widget.widget-accent a:not(.menu-link):not(.btn) {
      color: #0b3954 !important; }
  .widget > *:first-child {
    margin-top: 0; }
  .widget > *:last-child {
    margin-bottom: 0; }

.widget-title {
  margin: 15px 0 10px;
  font-weight: 800;
  letter-spacing: -0.0375em; }

.widget-emergency {
  padding: 0;
  border: none;
  background-color: transparent;
  text-align: center;
  line-height: 0em; }
  .widget-emergency .icon-stopwatch {
    fill: #bbb;
    width: 50px;
    height: 57px;
    line-height: 0em; }
  .widget-emergency .widget_wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center; }
    .widget-emergency .widget_wrapper .emergency_media_wrapper {
      margin-right: 10px; }

.widget_menu .widget-title {
  padding: 10px 5px 5px; }

.widget_menu .menu-item.active .menu-link {
  color: #dc0230; }
  .widget_menu .menu-item.active .menu-link .caret-push {
    fill: #dc0230; }

.widget_menu .menu-item:not(:last-child) {
  border-bottom: 1px dotted #ddd; }

.widget_menu .menu-link {
  font-size: 15px;
  font-size: 0.9375rem;
  padding: 10px;
  font-weight: 600;
  color: #444; }
  .widget_menu .menu-link:hover {
    color: #17378e; }
    .widget_menu .menu-link:hover .caret-push {
      fill: #17378e; }

.widget_review {
  padding: 20px;
  border: 2px solid #f90;
  box-shadow: none; }
  .widget_review .review cite {
    vertical-align: middle; }
    .widget_review .review cite img {
      display: block;
      margin: 0 auto 10px;
      vertical-align: middle; }
  @media (min-width: 768px) and (max-width: 991px) {
    .widget_review .review cite img {
      display: inline-block;
      margin: 0 7px 0 0; } }

.block {
  padding: 30px 15px; }

.wrap > *:first-child {
  margin-top: 0; }

.wrap > *:last-child {
  margin-bottom: 0; }

.block_title {
  margin-bottom: 15px; }
  .block_title .block-title {
    margin: 0; }

.block-title {
  margin: 0 0 15px;
  font-size: 20px;
  font-size: 1.25rem; }
  @media (min-width: 992px) {
    .block-title {
      font-size: 22px;
      font-size: 1.375rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  .block_media,
  .block_text {
    flex: 0 0 87.5%;
    max-width: 87.5%;
    margin-left: auto;
    margin-right: auto; } }

.block_media img {
  border: 3px solid #fff; }

@media (max-width: 991px) {
  .block_media {
    margin-bottom: 15px; } }

.block_alert {
  text-align: center;
  font-weight: 600;
  border-top: 5px solid #fff;
  background-color: #41bcf8;
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ffc107;
  color: #222; }
  .block_alert .alert-title {
    font-size: 24px;
    font-size: 1.5rem;
    font-weight: 800; }
  .block_alert p.lead {
    -webkit-transform: skew(-10deg);
    -moz-transform: skew(-10deg);
    -o-transform: skew(-10deg);
    transform: skew(-10deg); }
  @media (min-width: 992px) {
    .block_alert {
      text-align: left; }
      .block_alert .row {
        align-items: center;
        justify-content: center; }
      .block_alert .alert_title {
        flex: 0 0 18.75%;
        max-width: 18.75%; }
      .block_alert .alert_text {
        flex: 0 0 62.5%;
        max-width: 62.5%; } }
  @media (min-width: 1200px) {
    .block_alert .alert_text {
      flex: 0 0 56.25%;
      max-width: 56.25%; } }

.block_banner {
  padding: 30px 15px;
  text-align: center; }
  @media (min-width: 768px) and (max-width: 991px) {
    .block_banner {
      padding: 37px 15px; }
      .block_banner .row {
        align-items: center; } }
  @media (min-width: 768px) {
    .block_banner .wrap {
      padding: 0; }
    .block_banner .banner_branding {
      flex: 0 0 37.5%;
      max-width: 37.5%; }
      .block_banner .banner_branding .branding-logo {
        margin-top: 0; }
    .block_banner .banner_contact {
      flex: 0 0 62.5%;
      max-width: 62.5%; } }
  @media (min-width: 992px) {
    .block_banner {
      padding: 21px 15px; }
      .block_banner .row {
        align-items: flex-start; }
      .block_banner .banner_branding {
        flex: 0 0 25%;
        max-width: 25%; }
        .block_banner .banner_branding .branding-logo {
          margin-top: 24px; }
      .block_banner .banner_contact {
        flex: 0 0 75%;
        max-width: 75%; }
      .block_banner .banner_menu {
        flex: 0 0 62.5%;
        max-width: 62.5%;
        margin: -26px -5px 0 auto; } }
  @media (min-width: 992px) {
    .page_front .block_banner {
      padding: 21px 15px; }
      .page_front .block_banner .branding-logo {
        margin-top: 0; }
      .page_front .block_banner .banner_contact {
        margin-top: 28px; }
      .page_front .block_banner .banner_menu {
        margin: -65px -5px 0 auto; } }

.banner_button {
  text-align: left; }

.banner_branding {
  line-height: 0; }
  .banner_branding .branding-logo {
    margin: -40px auto 5px; }
  @media (min-width: 768px) {
    .banner_branding {
      text-align: left; } }

.banner_contact {
  margin-top: 15px; }
  .banner_contact .sprite-iicrc {
    width: 137px;
    height: 50px; }
  @media (min-width: 576px) {
    .banner_contact {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center; }
      .banner_contact .banner-call {
        margin-left: 15px; } }
  @media (min-width: 768px) {
    .banner_contact {
      margin-top: 0;
      text-align: right;
      justify-content: flex-end; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .page_front .banner_contact {
      display: block; } }

.banner_menu {
  margin-top: 30px; }

.block_announce {
  padding: 15px;
  font-weight: 800;
  letter-spacing: -0.0425em;
  line-height: 1em;
  text-align: center;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  border-bottom: 3px solid #fff;
  background-color: #dc0230;
  background: linear-gradient(to right, #a90225, #dc0230 25%, #dc0230 75%, #a90225);
  color: #fff; }
  .page_front .block_announce {
    border: none; }
  @media (min-width: 768px) {
    .block_announce .list-announce {
      display: flex;
      flex-wrap: wrap;
      flex-wrap: nowrap;
      align-items: center; }
      .block_announce .list-announce li {
        margin: 0;
        width: 33.33333%; } }

.page_title {
  padding: 45px 0 0;
  color: #fff;
  /* 	background-color: $color-secondary;
 	background: linear-gradient(
 		to right,
 		theme(secondary, dark),
 		theme(secondary, light) 40%,
 		theme(secondary, light) 60%,
 		theme(secondary, dark)
); */
  background: #41bcf8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #41bcf8; }
  .page_title .page_title_wrapper {
    padding: 10px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 25%, transparent 60%); }
  @media (min-width: 768px) {
    .page_title {
      padding-top: 90px; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 150px; } }

.page-title {
  margin: 0; }

.block_main.content {
  background-color: #fafafa; }

.page_front .block_main.content {
  background-color: transparent; }

@media (min-width: 992px) {
  .main .form-title {
    margin-top: 0; } }

.main > *:last-child {
  margin-bottom: 0; }

.sidebar .form {
  margin-bottom: 30px;
  border-radius: 6px;
  border: 2px solid #ddd; }

@media (max-width: 991px) {
  .sidebar {
    padding-top: 30px; } }

@media (max-width: 991px) {
  .page_contact .sidebar {
    padding-top: 0;
    padding-bottom: 30px; } }

.block_coverage {
  text-align: center;
  border-top: 1px solid #ededed;
  background-color: #fff; }
  .block_coverage .row {
    justify-content: center; }
  @media (min-width: 992px) {
    .block_coverage .wrap {
      flex: 0 0 75%;
      max-width: 75%; } }
  @media (min-width: 1200px) {
    .block_coverage .wrap {
      flex: 0 0 68.75%;
      max-width: 68.75%; } }

.site_info {
  background-color: #dc0230;
  background: linear-gradient(to right, #102662, #17378e 40%, #17378e 60%, #102662);
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  color: #fff; }
  .site_info a {
    color: #fff; }
  .site_info .phone {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 800;
    color: #fff; }
  .site_info .list > li:not(:last-child) {
    margin-bottom: 4px; }
  @media (min-width: 576px) {
    .site_info .row {
      align-items: center; }
    .site_info .info_company {
      flex: 0 0 62.5%;
      max-width: 62.5%; }
    .site_info .info_copyright {
      flex: 0 0 37.5%;
      max-width: 37.5%;
      text-align: right; }
    .site_info .list {
      margin-bottom: 0; } }

.front_jumbo {
  padding: 45px 30px 15px; }
  .front_jumbo .row_jumbo {
    border: 1px solid #ddd;
    box-shadow: 0 40px 30px -40px rgba(0, 0, 0, 0.6); }
    .front_jumbo .row_jumbo .wrap {
      padding: 0; }
  @media (min-width: 768px) {
    .front_jumbo {
      padding: 45px 0 0; }
      .front_jumbo .container_jumbo {
        margin: -80px auto 0;
        max-width: 1366px;
        padding: 50px;
        overflow-y: visible;
        background: url("/_/images/layout/bubbles-left.png"), url("/_/images/layout/bubbles-right.png");
        background-repeat: no-repeat;
        background-size: 250px 362px;
        background-position: left top, right bottom; } }
  @media (min-width: 992px) {
    .front_jumbo .container_jumbo {
      margin-top: -30px;
      padding: 40px 30px 45px; }
    .front_jumbo .row_jumbo {
      max-width: none;
      align-items: stretch;
      border: 1px solid #e5e5e5; }
      .front_jumbo .row_jumbo .wrap {
        padding: 0; }
    .front_jumbo .jumbo_marketing {
      position: relative;
      flex: 0 0 68.75%;
      max-width: 68.75%; }
    .front_jumbo .jumbo_conversion {
      flex: 0 0 31.25%;
      max-width: 31.25%;
      background-color: #fff; } }

.jumbo_socials {
  margin-bottom: 30px;
  text-align: center; }
  .jumbo_socials .list-socials li.socials-title {
    display: block;
    margin-bottom: 15px;
    letter-spacing: -0.0375em;
    line-height: 1em;
    font-weight: 600;
    white-space: nowrap; }
    .jumbo_socials .list-socials li.socials-title strong {
      display: block;
      font-weight: 800; }
  .jumbo_socials .list-socials li {
    display: inline-block;
    margin: 0 10px;
    line-height: 0; }
    .jumbo_socials .list-socials li a {
      display: block;
      -webkit-transition: all 300ms;
      -moz-transition: all 300ms;
      -ms-transition: all 300ms;
      -o-transition: all 300ms;
      transition: all 300ms;
      -webkit-transform: scale(0.94);
      -moz-transform: scale(0.94);
      -o-transform: scale(0.94);
      transform: scale(0.94); }
      .jumbo_socials .list-socials li a:hover {
        -webkit-transition: all 300ms;
        -moz-transition: all 300ms;
        -ms-transition: all 300ms;
        -o-transition: all 300ms;
        transition: all 300ms;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1); }
  .jumbo_socials svg {
    width: 117px;
    height: 30px; }
    .jumbo_socials svg.sprite-bbb {
      width: 156px;
      height: 40px; }
  @media (min-width: 768px) {
    .jumbo_socials svg.sprite-bbb {
      width: 195px;
      height: 50px; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .jumbo_socials svg.sprite-bbb {
      width: 156px;
      height: 40px; } }
  @media (min-width: 992px) {
    .jumbo_socials .list-socials {
      display: flex;
      flex-wrap: none;
      align-items: center;
      justify-content: space-between;
      text-align: left; }
      .jumbo_socials .list-socials li, .jumbo_socials .list-socials li.socials-title {
        display: block;
        width: auto;
        margin: 0; } }

.slider_jumbo {
  border: 3px solid #fff;
  background-color: #fff; }
  @media (min-width: 768px) {
    .slider_jumbo .slide {
      background-size: cover;
      background-position: top center; }
    .slider_jumbo .slide-placeholder {
      opacity: 0; }
    .slider_jumbo .slide_1 {
      background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
    .slider_jumbo .slide_2 {
      background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
    .slider_jumbo .slide_3 {
      background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
    .slider_jumbo .slide_4 {
      background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
    .slider_jumbo .slide_5 {
      background-image: url("/_/images/slides/jumbo/5-lg.jpg"); }
    .slider_jumbo .slide_6 {
      background-image: url("/_/images/slides/jumbo/6-lg.jpg"); } }

.front_services {
  padding: 30px 0 45px; }
  .front_services .service_item_wrapper {
    background-image: linear-gradient(to bottom, transparent 25%, #41bcf8 25%, #41bcf8 75%, transparent 75%); }
  .front_services .row {
    justify-content: center; }
  @media (min-width: 768px) {
    .front_services .service_item_wrapper {
      background-image: linear-gradient(to bottom, transparent 35%, #41bcf8 35%, #41bcf8 65%, transparent 65%); } }

.service_item {
  flex: 0 0 37.5%;
  max-width: 37.5%;
  padding: 15px; }
  @media (min-width: 768px) {
    .service_item {
      flex: 0 0 25%;
      max-width: 25%; } }

.service-item {
  -webkit-transition: all 300ms;
  -moz-transition: all 300ms;
  -ms-transition: all 300ms;
  -o-transition: all 300ms;
  transition: all 300ms;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  letter-spacing: -0.0425em;
  text-align: center;
  text-decoration: none;
  border-radius: 100%;
  border: 5px solid #fff;
  box-shadow: 0 0 0 5px transparent;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-image: url("/_/images/gallery/front/services-sm.webp");
  background-size: 600px 150px;
  background-position: 0 0;
  color: #222; }
  .service-item:hover {
    -webkit-transition: all 300ms;
    -moz-transition: all 300ms;
    -ms-transition: all 300ms;
    -o-transition: all 300ms;
    transition: all 300ms;
    box-shadow: 0 0 0 7px #41bcf8;
    color: #222; }
  .service-item strong {
    display: block;
    width: 100%;
    padding: 14px 5px;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 800;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.6); }
  .service-item.item-carpet {
    background-position: -150px 0; }
  .service-item.item-upholstery {
    background-position: -300px 0; }
  .service-item.item-rugs {
    background-position: -450px 0; }
  @media (min-width: 768px) {
    .service-item {
      background-image: url("/_/images/gallery/front/services-lg.webp"); } }
  @media (min-width: 992px) {
    .service-item {
      width: 200px;
      height: 200px;
      font-size: 19px;
      font-size: 1.1875rem;
      background-size: 800px 200px; }
      .service-item strong {
        padding: 21px 5px; }
      .service-item.item-carpet {
        background-position: -200px 0; }
      .service-item.item-upholstery {
        background-position: -400px 0; }
      .service-item.item-rugs {
        background-position: -600px 0; } }
  @media (min-width: 1200px) {
    .service-item {
      width: 240px;
      height: 240px;
      font-size: 21px;
      font-size: 1.3125rem;
      background-size: 960px 240px; }
      .service-item strong {
        padding: 27px 5px; }
      .service-item.item-carpet {
        background-position: -240px 0; }
      .service-item.item-upholstery {
        background-position: -480px 0; }
      .service-item.item-rugs {
        background-position: -720px 0; } }

.front_reviews {
  padding: 0 15px 45px;
  position: relative;
  z-index: 10;
  text-align: center; }
  .front_reviews .row {
    align-items: center;
    justify-content: center; }
  .front_reviews .block_text {
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 400px;
    border: 1px solid #eaeaea;
    box-shadow: 0 40px 20px -40px rgba(0, 0, 0, 0.45);
    background-color: #fff; }
  .front_reviews .block_more {
    margin-bottom: 30px; }
  .front_reviews .block_media {
    flex: 0 0 37.5%;
    max-width: 37.5%; }
    .front_reviews .block_media img {
      display: block;
      margin: 0 auto;
      max-width: 150px;
      border: none; }
  @media (min-width: 992px) and (max-width: 1199px) {
    .front_reviews .block_media img {
      width: 85%; } }
  @media (min-width: 992px) {
    .front_reviews .row {
      justify-content: center; }
    .front_reviews .block_text {
      order: 2;
      flex: 0 0 62.5%;
      max-width: 62.5%; }
    .front_reviews .block_media {
      flex: 0 0 18.75%;
      max-width: 18.75%; }
    .front_reviews .media_sun {
      order: 1; }
    .front_reviews .media_pgchamber {
      order: 3; }
    .front_reviews .block_more {
      order: 4;
      margin-bottom: 0; } }

.block_reviews {
  padding-bottom: 0;
  background-color: #fafafa; }
  .block_reviews .jumbo_socials {
    border-top: 1px solid #ddd;
    padding-top: 30px; }
  .page_reviews .block_reviews .jumbo_socials {
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 30px;
    border-top: none;
    border-bottom: 1px solid #ddd; }

.front_about {
  background-color: #fafafa; }
  @media (min-width: 992px) {
    .front_about {
      padding: 45px 0; }
      .front_about .about-wrapper {
        background: linear-gradient(to right, #fafafa 50%, rgba(23, 55, 142, 0.2) 50%), linear-gradient(135deg, #1e48ba 55%, #102662 90%); }
        .front_about .about-wrapper .about_media {
          background-color: transparent; }
      .front_about .container {
        padding: 5px 10px; }
      .front_about .row {
        align-items: center; }
      .front_about .block_media {
        order: 2;
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 25px; }
      .front_about .block_text {
        order: 1;
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 25px; }
        .front_about .block_text > *:last-child {
          margin-bottom: 0; } }

.about_media {
  padding: 20px;
  background-color: #17378e; }
  @media (min-width: 768px) {
    .about_media .img-wrap {
      border: 3px solid #fff;
      background-image: url("/_/images/gallery/front/about-md.webp");
      background-size: cover;
      background-position: center center; }
      .about_media .img-wrap img {
        opacity: 0; } }
  @media (min-width: 992px) {
    .about_media .img-wrap {
      width: 435px;
      height: 555px;
      background-image: url("/_/images/gallery/front/about-lg.webp"); } }
  @media (min-width: 1200px) {
    .about_media .img-wrap {
      width: 525px;
      height: 560px; } }

.front_think {
  padding: 0;
  background-color: #fafafa; }
  .front_think .think-wrapper {
    padding: 20px 0;
    background-color: #dc0230;
    /* background: linear-gradient(
	  		to right,
	  		theme(primary, dark),
	  		theme(primary, base) 25%,
	  		theme(primary, base) 75%,
	  		theme(primary, dark)
	  			); */
    background: #dc0230;
    background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #dc0230; }
  @media (min-width: 992px) {
    .front_think .row {
      align-items: center; }
    .front_think .block_media {
      flex: 0 0 50%;
      max-width: 50%; }
    .front_think .block_text {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 0; } }

.think_text {
  font-size: 19px;
  font-size: 1.1875rem;
  font-weight: 600;
  line-height: 1.125em;
  letter-spacing: -0.0375em;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  color: #fff; }
  .think_text strong {
    display: block;
    font-size: 125%;
    font-weight: 800; }
  @media (min-width: 992px) {
    .think_text {
      text-align: left; } }
  @media (min-width: 1200px) {
    .think_text {
      font-size: 21px;
      font-size: 1.3125rem; } }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt0 {
  margin-top: 0 !important; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt10 {
  padding-top: 10px !important; }

.pt15 {
  padding-top: 15px !important; }

.pt20 {
  padding-top: 20px !important; }

.pt30 {
  padding-top: 30px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pb15 {
  padding-bottom: 15px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pb30 {
  padding-bottom: 30px !important; }

.no-transform {
  transform: none !important; }

.no-boxshadow {
  box-shadow: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 400 !important; }

.hw1 {
  font-weight: 600 !important; }

.hw2 {
  font-weight: 800 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #444 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #dc0230 !important; }

.color--secondary {
  color: #17378e !important; }

.color--highlight {
  color: #41bcf8 !important; }

.color--accent {
  color: #0b3954 !important; }

.color--link {
  color: #17378e !important; }

.color--review {
  color: #f90 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #d61b0a !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #444 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--primary {
  background-color: #dc0230 !important; }

.bg--secondary {
  background-color: #17378e !important; }

.bg--highlight {
  background-color: #41bcf8 !important; }

.bg--link {
  background-color: #17378e !important; }

.bg--review {
  background-color: #f90 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #d61b0a !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #fafafa !important; }

.gdfancybg--text {
  background: #444;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #444; }

.gdfancybg--white {
  background: #fff;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #fff; }

.gdfancybg--primary {
  background: #dc0230;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #dc0230; }

.gdfancybg--secondary {
  background: #17378e;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #17378e; }

.gdfancybg--highlight {
  background: #41bcf8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #41bcf8; }

.gdfancybg--review {
  background: #f90;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #f90; }

.gdfancybg--success {
  background: #28a745;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #28a745; }

.gdfancybg--danger {
  background: #d61b0a;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #d61b0a; }

.gdfancybg--warning {
  background: #ffc107;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #ffc107; }

.gdfancybg--info {
  background: #0182C8;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.18) 0%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.18) 100%), linear-gradient(135deg, rgba(0, 0, 0, 0.09) 10%, transparent 45%, transparent 55%, rgba(0, 0, 0, 0.09) 90%), linear-gradient(45deg, transparent 20%, rgba(255, 255, 255, 0.22) 35%, transparent 35%), linear-gradient(45deg, transparent 60%, rgba(255, 255, 255, 0.22) 75%, transparent 75%), linear-gradient(135deg, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0.18) 40%, rgba(255, 255, 255, 0.18) 60%, rgba(255, 255, 255, 0) 85%), #0182C8; }
