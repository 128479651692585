.block_banner {
	padding: 30px 15px;
	text-align: center;
	//border-top: 10px solid $color-primary;

	@include media-breakpoint-only(md) {
		padding: 37px 15px;
		.row { align-items: center; }
	}

	@include media-breakpoint-up(md) {
		.wrap { padding: 0; }
		.banner_branding {
			@include make-col(6);
			.branding-logo { margin-top: 0; }
		}
		.banner_contact { @include make-col(10); }
	}

	@include media-breakpoint-up(lg) {
		padding: 21px 15px;
		.row { align-items: flex-start; }
		.banner_branding {
			@include make-col(4);
			.branding-logo { margin-top: 24px; }
		}
		.banner_contact { @include make-col(12); }
		.banner_menu {
			@include make-col(10);
			margin: -26px -5px 0 auto;
		}
	}

	.page_front & {
		@include media-breakpoint-up(lg) {
			padding: 21px 15px;
			.branding-logo { margin-top: 0; }
			.banner_contact { margin-top: 28px; }
			.banner_menu { margin: -65px -5px 0 auto; }
		}
	}

}



.banner_button { text-align: left; }



.banner_branding {
	line-height: 0;	
	.branding-logo { margin: -40px auto 5px; }
	@include media-breakpoint-up(md) {
		text-align: left;
	}
}



.banner_contact {
	margin-top: 15px;

	.sprite-iicrc {
		width: 137px;
		height: 50px;
	}

	@include media-breakpoint-up(sm) {
		@include make-flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		.banner-call { margin-left: 15px; }
	}

	@include media-breakpoint-up(md) {
		margin-top: 0;
		text-align: right;
		justify-content: flex-end;
	}

	.page_front & {
		@include media-breakpoint-only(md) {
			display: block;
		}
	}

}



.banner_menu {
	margin-top: 30px;
}