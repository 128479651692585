.front_jumbo {
 	padding: 45px 30px 15px;
	.row_jumbo {
		border: 1px solid #ddd;
  		box-shadow: 0 40px 30px -40px rgba(0, 0, 0, 0.6);
  		.wrap { padding: 0; }
	}

	@include media-breakpoint-up(md) {
		padding: 45px 0 0;
		.container_jumbo {
			margin: -80px auto 0;
			max-width: 1366px;
			padding: 50px;
			overflow-y: visible;
			background: url("/_/images/layout/bubbles-left.png"),
						url("/_/images/layout/bubbles-right.png");
			background-repeat: no-repeat;
			background-size: 250px 362px;
			background-position: left top, right bottom;
		}
	}

	@include media-breakpoint-up(lg) {

		.container_jumbo {
			margin-top: -30px;
			padding: 40px 30px 45px;
		}

		.row_jumbo {
			max-width: none;
			align-items: stretch;
			border: 1px solid #e5e5e5;
			.wrap { padding: 0; }
		}

		.jumbo_marketing {
			position: relative;
			@include make-col(11);
		}

		.jumbo_conversion {
			@include make-col(5);
			background-color: $white;
		}

	}

}



.jumbo_socials {
	margin-bottom: 30px;
	text-align: center;
	.list-socials {
		li.socials-title {
			display: block;
			margin-bottom: 15px;
			letter-spacing: -0.0375em;
			line-height: 1em;
			font-weight: $body-weight-bold;
			white-space: nowrap;
			strong {
				display: block;
				font-weight: $body-weight-bolder;
			}
		}
		li {
			display: inline-block;
			margin: 0 10px;
			line-height: 0;
			a {
				display: block;
				@include transition(all 300ms);
				@include transform(scale(0.94));
				&:hover {
					@include transition(all 300ms);
					@include transform(scale(1));
				}
			}
		}
	}
	svg {
		width: 117px;
		height: 30px;
 		&.sprite-bbb {
			width: 156px;
			height: 40px;
		}
	}
	@include media-breakpoint-up(md) {
		svg.sprite-bbb { width: 195px; height: 50px; }
	}
	@include media-breakpoint-only(lg) {
		svg.sprite-bbb { width: 156px; height: 40px; }
	}
	@include media-breakpoint-up(lg) {
		.list-socials {
			display: flex;
			flex-wrap: none;
			align-items: center;
			justify-content: space-between;
			text-align: left;
			li, li.socials-title {
				display: block;
				width: auto;
				margin: 0;
			}
		}
	}
}

	.slider_jumbo {
		border: 3px solid $white;
  		background-color: $white;
		@include media-breakpoint-up(md) {
			.slide {
				background-size: cover;
				background-position: top center;
			}
			.slide-placeholder { opacity: 0; }
			.slide_1 { background-image: url("/_/images/slides/jumbo/1-lg.jpg"); }
			.slide_2 { background-image: url("/_/images/slides/jumbo/2-lg.jpg"); }
			.slide_3 { background-image: url("/_/images/slides/jumbo/3-lg.jpg"); }
			.slide_4 { background-image: url("/_/images/slides/jumbo/4-lg.jpg"); }
			.slide_5 { background-image: url("/_/images/slides/jumbo/5-lg.jpg"); }
			.slide_6 { background-image: url("/_/images/slides/jumbo/6-lg.jpg"); }
		}
	}