.page_title {
	padding: 45px 0 0;
	//border-bottom: 5px solid $white;
	color: $white;

/* 	background-color: $color-secondary;
 	background: linear-gradient(
 		to right,
 		theme(secondary, dark),
 		theme(secondary, light) 40%,
 		theme(secondary, light) 60%,
 		theme(secondary, dark)
); */
	
	@include gdfancy-bg($color-highlight);

	.page_title_wrapper {
		padding: 10px;
		//background-color: rgba(black, 0.2);
		background-image: linear-gradient(
			to right,
			rgba(black, 0.1) 25%,
			transparent 60%
		);
	}

	@include media-breakpoint-up(md) { padding-top: 90px; }
	@include media-breakpoint-up(lg) { padding-top: 150px; }

}

	.page-title { margin: 0; }
