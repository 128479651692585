.front_reviews {
	padding: 0 15px 45px;
	position: relative;
	z-index: 10;
	text-align: center;

	.row { align-items: center; justify-content: center; }

	.block_text {
		margin-bottom: 30px;
		padding: 30px;
		border-radius: 400px;
		border: 1px solid #eaeaea;
  		box-shadow: 0 40px 20px -40px rgba(0, 0, 0, 0.45);
		background-color: $white;
	}

	.block_more { margin-bottom: 30px; }

	.block_media {
		@include make-col(6);
		img {
			display: block;
			margin: 0 auto;
			max-width: 150px;
			border: none;
		}
	}

	@include media-breakpoint-only(lg) {
		.block_media img {
			width: 85%;
		}
	}

	@include media-breakpoint-up(lg) {
		.row { justify-content: center; }
		.block_text {
			order: 2; 
			@include make-col(10);
		}
		.block_media { @include make-col(3); }
			.media_sun { order: 1; }
			.media_pgchamber { order: 3; }
		.block_more { 
			order: 4;
			margin-bottom: 0;
		}
	}

	@include media-breakpoint-up(xl) {
		//.wrap { @include make-col(10); }
	}

}

.block_reviews {
	padding-bottom: 0;
	background-color: $body-bg;
	.jumbo_socials {
		border-top: 1px solid #ddd;
		padding-top: 30px;
	}
	.page_reviews & {
		.jumbo_socials {
			margin-bottom: 0;
			padding-top: 0;
			padding-bottom: 30px;
			border-top: none;
			border-bottom: 1px solid #ddd;
		}
	}
}