$nav-mobile-bg: 			theme(secondary, base);
$mobile-link-bg: 			transparent;
$mobile-link-color:			$white;
$mobile-hover-bg: 			theme(primary, base);
$mobile-hover-color: 		$white;
$mobile-active-bg: 			$white;
$mobile-active-color: 		theme(primary, base);

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 90%;
	max-width: 320px;
	height: 100%;
	padding: 30px 15px;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	h4 {
		font-size: 1.2em;
		font-weight: 800;
		text-transform: uppercase;
	}

	.menu {
		border-bottom: 1px solid $white;
		padding-bottom: 30px;
		margin-bottom: 10px;
	}

	.menu > .menu-item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.3); }
	.menu > .menu-item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.2); }

	.menu > .menu-item {

		&.active .menu-link {
			font-weight: $body-weight-bolder;
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			border-bottom: none;
			> .menu-link { background-color: $mobile-hover-bg; }
		}

	}	

		.menu > .menu-item > .menu-link {
			padding: 12px 10px;
			font-weight: 600;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
		}

	.dropdown-menu {
		min-width: 0;
		margin-top: 5px;
		padding: 0;
		background-color: $mobile-hover-bg;
		.menu-item {
			font-size: 0.925em;
			&:not(:last-child) { border-bottom: 1px solid rgba(black, 0.3); }
			&:not(:first-child) { border-top: 1px solid rgba($white, 0.3); }			
			&.active .menu-link {
				color: theme(primary, base);
				letter-spacing: -0.0275em;
				font-weight: $body-weight-bolder;
				.caret-push {
					width: 0;
					margin-right: 0;
				}				
			}
		}

			.menu-link {
				font-weight: $body-weight-bold;
				background-color: transparent;
				color: $white;
				&:hover, &:focus {
					outline: none;
					letter-spacing: -0.0275em;
					color: theme(primary, base);
				}				
				.caret-push { fill: theme(primary, base); }
			}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

