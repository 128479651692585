.front_services {
	padding: 30px 0 45px;

	.service_item_wrapper {
		background-image: linear-gradient(
			to bottom,
			transparent 25%,
			$color-highlight 25%,
			$color-highlight 75%,
			transparent 75%
		);
	}

	.row { justify-content: center; }

	@include media-breakpoint-up(md) {
		.service_item_wrapper {
			background-image: linear-gradient(
				to bottom,
				transparent 35%,
				$color-highlight 35%,
				$color-highlight 65%,
				transparent 65%
			);
		}
	}

}

.service_item {
	@include make-col(6);
	padding: 15px;
	@include media-breakpoint-up(md) {
		@include make-col(3,12);
	}
}

.service-item { 
	@include transition(all 300ms);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 150px;
	height: 150px;
	margin: 0 auto;
	letter-spacing: -0.0425em;
	text-align: center;
	text-decoration: none;
	border-radius: 100%;
	border: 5px solid $white;
	box-shadow: 0 0 0 5px transparent;
	box-shadow: 0 2px 4px 0 rgba(black,0.25);
	background-image: url("/_/images/gallery/front/services-sm.webp");
	background-size: 600px 150px;
	background-position: 0 0;
	color: #222;
	
	&:hover {
		@include transition(all 300ms);
		box-shadow: 0 0 0 7px $color-highlight;
		color: #222;
	}

	strong {
		display: block;
		width: 100%;
		padding: 14px 5px;
		border-radius: 5px;
		text-transform: uppercase;
		font-weight: $body-weight-bolder;
		text-transform: uppercase;
		background-color: rgba($white, 0.6);
	}

	&.item-carpet { background-position: -150px 0; }
	&.item-upholstery { background-position: -300px 0; }
	&.item-rugs { background-position: -450px 0; }

	@include media-breakpoint-up(md) {
		background-image: url("/_/images/gallery/front/services-lg.webp");
	} 

	@include media-breakpoint-up(lg) {
		width: 200px;
		height: 200px;
		@include font-size(19);
		background-size: 800px 200px;
		strong { padding: 21px 5px; }
		&.item-carpet { background-position: -200px 0; }
		&.item-upholstery { background-position: -400px 0; }
		&.item-rugs { background-position: -600px 0; }		
	}

	@include media-breakpoint-up(xl) {
		width: 240px;
		height: 240px;
		@include font-size(21);
		background-size: 960px 240px;
		strong { padding: 27px 5px; }
		&.item-carpet { background-position: -240px 0; }
		&.item-upholstery { background-position: -480px 0; }
		&.item-rugs { background-position: -720px 0; }		
	}

}

