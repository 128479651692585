.review {

	.review-wrap { 
		padding: 0 15px;
		.icon-review {
			margin: 0 auto 10px;
		}
	}

	p {
		margin-bottom: 0;
		line-height: 1.425em;
	}

	cite {
		display: block;
		margin-top: 5px
	}

	.icon-review {
		display: block;
		height: 25px;
		width: 125px;
		margin: 0 0 15px;
		fill: $color-review;
	}

}