.banner_menu {

	.menu {
		display: flex !important;
		flex-wrap: nowrap;
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;

		> .menu-item {
			display: inline-block;
			flex: 1;
			padding: 0 5px;


			&.active {
				> .menu-link {
					background: transparent !important;
					color: $color-primary !important;
					b { font-weight: $body-weight-bolder !important; }
				}
			}

			&.open {
				> .menu-link {
					background-color: $color-highlight;
					.caret { fill: $white !important; }
				}
			}

			> .menu-link {
				padding: 12px 18px;
				font-weight: $body-weight-bold;
				border-radius: 4px;
				//background-color: $color-secondary;
				background: $color-secondary url("/_/images/layout/hover.svg") center center no-repeat;
				background-size: 0;
				color: $white;
				.caret { fill: $white; }

				&:hover {
					//background-color: $color-highlight;
					background-size: 115%;
				}

			}
		}
	}

	.menu-link {
		display: block;
		padding: 10px;
		font-weight: $body-weight-bold;
		white-space: nowrap;
		background-color: transparent;
		color: $white;
	}

	.dropdown-menu {
		margin-top: 5px;
		border-radius: 4px;
		background-color: $color-highlight;
		
		.menu-item {
			&:not(:last-child) { border-bottom: 1px solid rgba(black, 0.16); }
			&:not(:first-child) { border-top: 1px solid rgba($white, 0.48); }
			&.active {
				.menu-link {
					color: theme(primary, base);
					letter-spacing: -0.0275em;
					font-weight: $body-weight-bolder;
					.caret-push {
						width: 0;
						margin-right: 0;
					}
				}
			}
		}

		.menu-link {
			&:hover {
				//letter-spacing: -0.0275em;
				//color: theme(primary, base);
			}
			.caret-push { fill: $white; }
		}


/* 		@include media-breakpoint-up(lg) {
	background-color: theme(primary, base);
	.menu-item.active .menu-link { color: theme(secondary, base); }
	.menu-link {
		&:hover { color: theme(secondary, base); }
		.caret-push { fill: theme(secondary, base); }
	}
} */

	}

}

