.block {
	padding: 30px 15px;
}

	.wrap > *:first-child { margin-top: 0; }
	.wrap > *:last-child { margin-bottom: 0; }

	.block_title {
		margin-bottom: 15px;
		.block-title { margin: 0; }
	}

		.block-title {
			margin: 0 0 15px;
			@include font-size(20);
			@include media-breakpoint-up(lg) { @include font-size(22); }
		}


.block_media,
.block_text {
	@include media-breakpoint-only(md) {
		@include make-col(14);
		margin-left: auto; margin-right: auto;
	}
}

.block_media {
	img { border: 3px solid $white; }
	@include media-breakpoint-down(md) {
		margin-bottom: 15px;
	}
}


.block_alert {
	text-align: center;
	font-weight: $body-weight-bold;
	border-top: 5px solid $white;
	background-color: $color-highlight;
	@include gdfancy-bg($yellow);
	color: #222;
	
	.alert-title {
		@include font-size(24);
		font-weight: $body-weight-bolder;
	}

	$hcolor: $color-secondary;
	$hopac: 0.8;

	p.lead { 
		//font-style: italic;
		@include transform(skew(-10deg));
	}

	@include media-breakpoint-up(lg) {
		text-align: left;
		.row { align-items: center; justify-content: center; }
		.alert_title { @include make-col(3); }
		.alert_text { @include make-col(10); }
	}

	@include media-breakpoint-up(xl) {
		.alert_text { @include make-col(9); }
	}

}