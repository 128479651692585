.slider_jumbo {

    margin: 0 auto;
    line-height: 0px;

    .slick-prev,
    .slick-next {
        opacity: 0;
    }

    .slide-placeholder { width: 100%; }

    .slide { position: relative; }

    .slide_caption {
        position: absolute;
        left: 0; right: 0; bottom: 0;
        width: 100%;
        padding: 10px 20px;
        @include font-size(16);
        font-weight: $heading-weight-bolder;
        line-height: 1em;
        text-align: center;
        text-shadow: 0 1px 2px rgba(black, 0.5);
        background-color: rgba($color-highlight, 0.8);
        color: $white;
        strong { font-weight: $heading-weight-bolder; }
    }

    @include media-breakpoint-up(md) {
        .slide_caption { @include font-size(24); }
    }

    @include media-breakpoint-up(lg) {
        .slide {
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
        }
        .slide_caption {
            position: absolute;
            top: 50%;
            right: auto; bottom: auto;
            display: inline-block;
            width: auto;
            padding: 15px 90px 15px 15px;
            @include transform(translateY(-50%));
            @include font-size(30);
            letter-spacing: -0.0425em;
            text-align: left;
            background-color: transparent;
            background-image: linear-gradient(
                to right,
                rgba($color-highlight,0.8) 70%,
                transparent
            );
            strong { display: block; }
        }
    }

    @include media-breakpoint-up(xl) {
        .slide_caption {
            @include font-size(36);
            letter-spacing: -0.0575em;
        }
    }

}