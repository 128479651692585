.front_about {
	background-color: $body-bg;
	@include media-breakpoint-up(lg) {
		padding: 45px 0;
		.about-wrapper {
			background: 
				linear-gradient(to right, $body-bg 50%, rgba($color-secondary,0.2) 50%),
				linear-gradient(135deg, theme(secondary, light) 55%, theme(secondary, dark) 90%);
			.about_media { background-color: transparent; }
		}
		.container { padding: 5px 10px; }
		.row { align-items: center; }
		.block_media {
			order: 2;
			@include make-col(8);
			padding-left: 25px;
		}
		.block_text {
			order: 1;
			@include make-col(8);
			padding-right: 25px;
			>*:last-child { margin-bottom: 0; }
		}
	}
}

	.about_media {
		padding: 20px;
		background-color: $color-secondary;
		@include media-breakpoint-up(md) {
			.img-wrap {
				border: 3px solid #fff;
				background-image: url("/_/images/gallery/front/about-md.webp");
				background-size: cover;
				background-position: center center;
				img { opacity: 0; }
			}
		}
		@include media-breakpoint-up(lg) {
			.img-wrap {
				width: 435px; height: 555px;
				background-image: url("/_/images/gallery/front/about-lg.webp");
			}
		}
		@include media-breakpoint-up(xl) {
			.img-wrap {
				width: 525px; height: 560px;
			}
		}
	}